@import '../../theme/variables';
@import '../../theme/snippets';

.default_button {
  @extend %button-shape;
  padding: 12px;
  height: 48px;

  span {
    flex-shrink: 0;
  }

  &.iconOnly {
    padding: 12px 8px;
  }
  &.largeIcon {
    padding: 4px;
  }

  &.fit {
    width: max-content;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &.high {
    height: 56px;
  }

  &.low {
    height: 40px;
  }

  &.blue {
    background-color: $primary-color-00;
    p {
      color: $neutral-white;
    }
    &:hover {
      background-color: $primary-color-01;
    }
    &:active {
      background-color: $primary-color-02;
    }
    &:disabled {
      border: 2px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.white {
    border: 1px solid $secondary-color-05;
    background-color: $neutral-white;

    &:hover {
      border: 1px solid #48555e;
      background-color: #F6F6F6;
    }
    p {
      color: $secondary-color-05;
    }
    &:active {
      background-color: $neutral-gray;
    }
    &:disabled {
      border: 1px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.whiteBlack {
    border: 1px solid $secondary-color-00;
    background-color: $neutral-white;

    &:hover {
      border: 1px solid #48555e;
      background-color: #F6F6F6;
    }
    p {
      color: $secondary-color-00;
    }
    &:active {
      background-color: $neutral-gray;
    }
    &:disabled {
      border: 1px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.withoutBorder {
    border: none !important;
    &:hover {
      border: none;
    }
  }
  &.whiteBlue {
    border: 2px solid $primary-color-00;
    background-color: $neutral-white;

    &:hover {
      border: 2px solid $primary-color-01;
      p {
        color: $primary-color-01;
      }
    }
    p {
      color: $primary-color-00;
      font-weight: 500;
    }
    &:active {
      background-color: $neutral-gray;
    }
    &:disabled {
      border: 2px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.red {
    background-color: $error-bride;
    p {
      color: $neutral-white;
    }
    &:hover {
      background-color: $error-hover;
    }
    &:active {
      background-color: $error-active;
    }
    &:disabled {
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.darkBlue {
    background-color: $dark-blue-color-00;
    p {
      color: $neutral-white;
    }
    &:hover {
      background-color: $dark-blue-color-01;
    }
    &:active {
      background-color: $dark-blue-color-02;
    }
    &:disabled {
      border: 2px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.gray {
    background-color: $dark-gray;
    p {
      color: $neutral-white;
    }
    &:hover {
      background-color: $secondary-color-00;
    }
    &:active {
      background-color: $secondary-color-02;
    }
    &:disabled {
      border: 2px solid #dfe3e5;
      background-color: $neutral-gray;
      p {
        color: $secondary-color-03;
      }
    }
  }

  &.transparent {
    background-color: unset;
    border: 2px solid $neutral-white;
    p {
      color: $neutral-white;
    }
    &:active {
      background-color: $white-transparent;
    }
    &:disabled {
      background-color: $slider-cover;
      border: 2px solid $secondary-color-03;
      p {
        color: $secondary-color-03;
      }
    }
  }

  p.indent {
    margin-left: 11px;
  }

  .content {
    margin: 0;
    font-size: 16px;
    font-family: "Goldplay-SemiBold";
  }

  .boldText {
    margin: 0;
    font-weight: 500;
  }

  &.wide {
    width: 100%;
  }

  &.slim {
    width: 168px;
  }

  &.slimmer {
    width: 144px;
  }

  &.slimy {
    width: 204px;
  }

  &.middle {
    width: 300px;
  }

  &.auto {
    width: auto;
    margin-left: unset;
    transform: unset;
  }
  &.small {
    height: 32px;
    width: 96px;
  }

  &.square {
    width: 40px;
    height: 40px;
    margin-left: unset;
    transform: unset;
  }

  &.mobRow4 {
    width: 22%;
    margin-left: unset;
    transform: unset;
  }

  .buttonSpinner {
    margin-right: 8px;
  }
}

@media (max-width: $tablets-width) {
  .default_button {
    &.slim {
      width: 100%;
    }

    &.slimy {
      width: 100%;
    }
  }
}
