@import '../../theme/variables';

.burger {
  display: flex;
  cursor: pointer;

  span {
    display: block;
    height: 2px;
    background: $secondary-color-00;
    border-radius: 4px;
  }
}
