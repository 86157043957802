@import '../../theme/variables';

.container {
  display: flex;
  gap: 32px;
  width: 715px;
  height: 432px;
  padding: 0 40px;
  text-align: center;

  span {
    color: $primary-color-00;
    font-weight: 800;
  }

  h2 {
    margin-top: -46px;
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    color: $secondary-color-01;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .iphone {
    width: 235px;
    height: 480px;
    margin-top: -48px;
  }

  &.mobile {
    width: 264px;
    height: 460px;
    padding: 0;

    h2 {
      font-size: 36px;
      line-height: 46px;
      margin: -36px 0 24px;
    }

    .iphone {
      margin-top: 32px;
      width: 343px;
      max-width: 100%;
      height: 588px;

      span {
      img {
        object-fit: contain !important;
        object-position: top !important;
      }

      }

    }
  }

  .storeButtons {
    display: flex;
    gap: 24px;
    margin-top: 44px;
  }
}

@media (max-width: 1000px) {
  .container {
    display: block;
  }
}

@media (max-width: 384px) {
  .container {
    &.mobile {
      width: 100%;

      h2 {
        font-size: 36px;
        line-height: 40px;
        margin: -36px 0 24px;
      }
    }
  }
}

@media (max-width: 320px) {
  .container {
    &.mobile {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
}
