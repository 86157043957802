.container {
  width: 100%;
  height: 1px;
  overflow: hidden;

  .title {
    width: 100%;

  }

  .description {
    width: 100%;
  }

  .mainImage {
    width: 100%;
    height: 100%;
  }
}
