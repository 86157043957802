@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  @extend %centred-content;
  position: relative;
  background-color: $neutral-gray;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-weight: 500;
  user-select: none;
  overflow: hidden;
  flex-shrink: 0;

  &Active {
    background-color: $secondary-color-03;
  }
}

.initials-male {
  color: $primary-color-00;
}
.initials-female {
  color: $primary-color-00;
}
.initials-other {
  color: $primary-color-00;
}
