@import '../../theme/variables';
@import '../../theme/snippets';

.cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: -100vh;
  left: 0;
  z-index: 49;
  background-color: rgba(43, 48, 51, 0.5);
  opacity: 0;
  transition: opacity 0.3s;

  &.active {
    opacity: 1;
    top: 0;
  }
}

.container {
  background-color: $neutral-white;
  filter: drop-shadow(0px 8px 24px rgba(72, 85, 94, 0.15));
  position: fixed;
  left: -400px;
  top: 0;
  transition: 0.3s;
  max-width: 400px;
  //overflow-y: auto;
  //overflow-x: hidden;
  z-index: 50;
  height: 100vh;
  width: 100vw;

  &.active {
    left: 0;
    transition: 0.3s;
  }

  .header {
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 80px;
    background-color: $secondary-color-01;
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;

    .closeIcon {
      position: relative;
    }
  }

  .scrollableContent {
    @extend %hidden-scroll;
    height: calc(100vh - 80px);
  }

  .menuItem {
    height: 40px;
    padding-left: 24px;

    p {
      color: $secondary-color-00;
    }
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid $secondary-color-03;
  }

  .title {
    padding: 8px 0 0 24px;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-color-02;
  }
}

@media screen and (min-width: 1349px) {
  .container {
    .hideOnDesktop {
      display: none;
    }
  }
}
