@import '../../theme/variables';
@import '../../theme/snippets';

.footer {
  padding: 5px 116px 0 162px;
  background: #fafafa;

  .info {
    @extend %centred-content;
    width: 100%;
    height: 86px;
    list-style-type: none;
    padding: 0 0 1rem 0;
    margin: 0;
    justify-content: center;
    gap: 0 4rem;

    a {
      font-size: 16px;
      color: $secondary-color-00;
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #dfe3e5;

    .socialMedia {
      width: 277px;
      height: 150px;

      .contactUs {
        span {
          color: $secondary-color-00;
          font-size: 14px;
        }

        .brands {
          display: flex;
          margin-top: 12px;

          a {
            &:first-child {
              margin-left: 0;
            }
            margin-left: 21px;
          }
        }
      }

      .downloadButtons {
        @extend %centred-content;
        width: 100%;
        justify-content: space-between;
        margin-top: 16px;

        .appStore,
        .googlePlay {
          cursor: pointer;
        }
      }
    }
    .content {
      @extend %centred-content;
      width: 812px;
      min-height: 204px;
      justify-content: space-between;
      flex-wrap: wrap;

      .tab {
        width: 204px;

        .title {
          font-size: 14px;
          color: $secondary-color-00;
        }

        .tabItems {
          display: flex;
          flex-direction: column;

          span {
            margin-top: 8px;
            font-size: 16px;
            color: $secondary-color-02;
            &:first-child {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer {
    padding-left: 48px;

    .wrapper {
      .content {
        width: 50%;
        justify-content: end;

        .tab {
          margin-bottom: 24px;
          width: 140px;
        }
      }
    }
  }
}

@media screen and (max-width: 743px) {
  .footer {
    padding: 2rem 1rem 3rem;

    .info {
      flex-wrap: wrap;
      display: block;
      text-align: center;
      line-height: 2rem;
    }

    .wrapper {
      flex-wrap: wrap;
      justify-content: center;

      .socialMedia {
        display: flex;
        flex-direction: column;
        align-items: center;

        .contactUs {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }


      .content {
        width: 100%;

        .tab {
          margin-bottom: 24px;
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobiles-width) {
  .footer {
    padding: 1.5rem 1rem;
  }
}
