.cower {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.opened {
  opacity: 1;
  transition: opacity 0.2s;
}
