@import '../../theme/variables';

.autocomplete {
  width: 100%;
  position: absolute;
  left: 0;
  top: 48px;
  z-index: 10;
  background-color: $neutral-white;
  box-shadow: 0 8px 24px rgb(72 85 94 / 15%);
  border-top: none;

  .resultsList {
    list-style: none;
    padding: 15px;
    margin: 0;

    .resultsItem {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $secondary-color-00;
      border-radius: 4px;

      &:hover {
        background-color: $brand-background;
        cursor: pointer;
      }

      &.withDesc {
        display: flex;
        justify-content: space-between;

        .title {
          white-space: nowrap;
          overflow: hidden;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .description {
          color: $secondary-color-02;
          flex: 1;
          margin-left: 5px;
          white-space: nowrap;
          text-align: right;
        }
      }

      &.line {
        padding: 0;
        margin: 8px 0;
        height: 1px;
        background-color: $secondary-color-03;
      }

      .resultOverlap {
        font-weight: 600;
      }
    }
  }
}
