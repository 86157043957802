@import '../../theme/variables';

.wrapper {
  position: relative;

  .img {
    position: absolute;
    left: 14px;
    top: 11px;
    z-index: 1;
    width: 24px;
  }

  .clearImage {
    position: absolute;
    right: 17px;
    top: 15px;
    z-index: 1;
    cursor: pointer;
    display: none;

    &.dropDownModeIcon {
      right: 0;
    }
  }

  .focusedClearImg {
    display: block;
  }

  .search {
    padding: 12px 36px 12px 48px;
    color: $secondary-color-02;
    width: 320px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #dfe3e5;
    font-size: 16px;
    position: relative;

    &::placeholder {
      color: $secondary-color-02;
    }
  }
  .wide {
    width: 100%;
  }

  input:focus,
  input:focus-visible {
    border: 2px solid $primary-color-00;
    outline: none;
  }
}

.hide {
  display: none;
}

@media screen and (max-width: $tablets-width) {
  .wrapper {
    margin-right: 0.5rem;

    .img {
      left: 10px;
      cursor: pointer;
    }

    .focusedInput {
      width: 100%;
    }

    .dropDownSearch {
      border: none;
    }

    .mobileSearch {
      border: 1px solid #dfe3e5;
    }
  }

  .focusedWrapper {
    margin-right: unset;
  }

  .hide {
    display: block;
  }
}
