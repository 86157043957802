@import '../../theme/variables';

.preloader {
  border: 3px solid $secondary-color-04;
  border-top-color: $primary-color-00;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
