@import '../../theme/variables';
@import '../../theme/snippets';

.radio:checked,
.radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radioItem {
  margin-bottom: 20px;
}

.radio:checked {
  border: 1px solid $primary-color-00;
}

.radio:checked + label,
.radio:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.radio:checked + label:before,
.radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid $secondary-color-02;
  border-radius: 100%;
  background: #fff;
}

.radio:checked + label:before {
  border: 1px solid $primary-color-00;
}

.radio:checked + label:after,
.radio:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: $primary-color-00;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.radio:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.radio:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.container {
  @extend %centred-content;
  //@extend %styledScroll;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  .modal {
    position: relative;
    width: 552px;
    width: min(552px, 90vw);
    min-height: 184px;
    max-height: 95vh;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #dfe3e5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.emptyModal {
      padding: 0;
    }
    &.largeModal {
      width: 80%;
      max-width: 1400px;
    }
    &.fitChild {
      width: fit-content;
      max-width: 90vw;
    }
    &.cutContent {
      overflow: hidden;
    }
    &.dropHorizontalPadding {
      padding-right: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      height: 100%;
      max-height: 90vh;

      .content {
        .crossIcon {
          right: 10px !important;
        }
      }
    }
    &.dropBottomPadding {
      padding-bottom: 0 !important;
    }

    .buttons {
      display: flex;
      justify-content: center;
      gap: 24px;
      margin-top: 1rem;

      button {
        width: 228px;
      }
    }

    .content {
      @extend %centred-content;
      flex-direction: column;
      margin-bottom: 24px;
      align-items: center;

      .title {
        max-width: 85%;
        font-weight: 500;
        color: $secondary-color-00;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        &.noTitleMargin {
          margin: 0;
        }

        &.centred {
          text-align: center;
        }

        &.centredSmaller {
          text-align: center;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        &.left {
          text-align: left;
          width: 100%;
          max-width: 100%;
        }
      }

      .description {
        margin-top: 8px;

        p {
          color: $secondary-color-02;
        }

        &.centred {
          text-align: center;
        }
      }

      .crossIcon {
        @extend %centred-content;
        width: 30px;
        height: 30px;
        position: absolute;
        top: -24px;
        right: -24px;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
          background-color: $neutral-gray;
        }
      }

      .icon {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.modalImage {
  min-height: 50vh;
  min-width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $tablets-width) {
  .container {
    .modal {
      &.largeModal {
        width: 100%;
      }
    }
  }
}

@media (max-width: $above-mobiles-width) {
  .container {
    .modal {
      padding: 16px 24px;

      &.fitChild {
        padding: 40px 1rem;

        .content {
          .crossIcon {
            top: -36px;
            right: -12px;
          }
        }
      }

      .content {
        .crossIcon {
          top: 0;
          right: 0;
        }
      }
    }
  }
}
