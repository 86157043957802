@import '../../theme/variables';

.headerContent {
  display: flex;
  gap: 1rem;

  .headerAvatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $secondary-color-00;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .firstLine {
    color: $neutral-white;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    gap: 0.5rem;

    p {
      color: $neutral-white;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .secondLine {
    color: $secondary-color-02;
    font-size: 14px;
    line-height: 20px;
  }
}

.heartUA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: $primary-color-025;

  .heartUALeft {
    display: flex;
    align-items: center;
    gap: 1rem;
    p {
      font-weight: 500;
    }
  }
}

.menuAfter {
  padding: 8px 24px 120px;
  p {
    color: $secondary-color-02;
    margin-bottom: 8px;
  }

  .afterIcons {
    position: relative;
    width: 100%;
    height: 54px;
    display: grid;
    grid-template-columns: 45% auto;
    grid-gap: 16px;

    .icon {
      position: relative;
    }
  }
}
