@import '../../theme/variables';

.systemSenderImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $brand-background;
  display: flex;
  align-items: center;
  justify-content: center;
}
