@import '../../theme/variables';
@import '../../theme/snippets';

.border {
  width: 100%;
  height: 1px;
  padding-bottom: 53px;
}

.menu {
  width: 100%;
  background-color: $neutral-white;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
  z-index: 20;

  .container {
    display: flex;
  }

  &.withBadge {
    top: 120px;
  }

  ul {
    @extend %centred-content;
    justify-content: flex-start;
    width: 100%;
    height: 54px;
    margin: 0;
    list-style-type: none;

    .dropdownWrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .underListWrapper {
      display: none;

      .burgerFooter {
        margin-top: 40px;
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }

    li.active {
      border-bottom: 2px solid $primary-color-00;
    }

    li {
      cursor: pointer;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      .arrow {
        display: none;
      }

      &:first-child {
        margin-left: 0;
      }

      a {
        font-size: 16px;
        color: $secondary-color-03;
      }
    }
  }
}

.divider {
  width: 1px;
  height: 32px;
  background-color: $secondary-color-03;
}

.sellTypeCell {
  width: 190px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DDF3F877;

  p {
    font-weight: 400;
    font-size: 16px;
  }
}

.sellActive {
  background-color: $primary-color-00;
}
.textActive {
  color: $neutral-white;
}

.submenuItem {
  margin: 0 40px 0 24px;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  white-space: nowrap;

  &.active {
    border-bottom: 2px solid $primary-color-00;
  }
}

.extendedMenu {
  display: flex;
  width: 100%;
  height: 0;
  transition: all 0.2s;

  &.mobileExtendedMenu {
    display: none;
  }
}

.mobileExtendedMenu {
  display: none;
}

.openExtended {
  height: fit-content;
  min-height: 40px;
  position: fixed;
  z-index: 20;
  background-color: white;
  top: 130px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  &.withBadge {
    top: 170px;
  }
}

.extendedMenuContent,
.extendedMenuBrandContent {
  p {
    font-size: 0.88rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }
}

.extendedMenuContent {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  gap: 1rem;
}

.extendedMenuBrandWrapper,
.extendedMenuContent {
  padding: 32px 48px;

  h4 {
    margin: 0 0 1rem 0;
  }
}

.extendedMenuBrandContent {
  columns: 200px auto;
  overflow: auto;
  width: 90vw;
  max-height: calc(100vh - 194px);

  a {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.allLinkItem {
  font-weight: 500;
  margin-top: 0.5rem;

  .extendedMenuLinks {
    p {
      color: $primary-color-00;

      &:hover {
        color: $primary-color-01;
      }
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: 4;
  background-color: #2B303399;
}

@media screen and (max-width: $tablets-width) {
  .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    z-index: 4;
    background-color: #2B303399;
  }

  .border {
    display: none;
  }

  .menu {
    position: fixed;
    width: 100vw;
    z-index: 10;
    height: calc(100vh - 80px);
    overflow-y: auto;
    transition: 0.3s;

    .container {
      height: calc(100vh - 44px);
      overflow-y: auto;

      .sideMenu,
      .divider {
        display: none;
      }

      ul {
        width: 100%;
        height: fit-content;
        min-height: calc(100vh - 44px);
        padding: 0;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow-y: auto;

        .dropdownWrapper {
          flex-direction: column;
        }

        .buttons {
          width: 343px;
          display: flex;
          justify-content: space-between;

          button {
            width: 163.5px;
          }
        }

        .underListWrapper {
          display: block;
          width: calc(100vw - 32px);
          margin: 32px 16px;
          border-top: 1px solid #ebeef0;
          background-color: white;
        }

        li {
          position: relative;
          padding: 32px 32px 0;
          display: flex;
          justify-content: space-between;
          width: 100%;

          p {
            font-size: 24px;
            color: $secondary-color-00;
            margin: 0;
          }

          .arrow {
            display: block;
          }

          &:first-of-type {
            margin-top: 0;
          }
        }

        nav {
          background-color: $neutral-gray;
        }
      }
    }
  }

  .submenuItem {
    &.active {
      border-bottom: unset;
    }
  }

  .desktopExtendedMenu {
    display: none;
  }

  .mobileExtendedMenu {
    display: flex;
    height: 0;
    width: 100%;
    overflow: hidden;
    transition: height 0.3s;

    .extendedMenuContent,
    .extendedMenuBrandWrapper {
      padding: 1rem 0 0 2rem;

      p {
        font-size: 1rem;
      }
    }

    &.mobileExtendedMenuOpened {
      height: calc(100vh - 450px);
      overflow: auto;

      &.withBadge {
        height: calc(100vh - 410px);
      }
    }
  }

  .extendedMenuContent {
    //width: 100%;
    grid-template-columns: 210px 210px;
  }

  .extendedMenuBrandContent {
    columns: auto;
  }

  .open {
    right: 0;
  }

  .close {
    right: -100vw;
    transition: 0.3s;
  }

  .mobileAppFooterButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $secondary-color-03;
    margin-bottom: 1rem;
  }

  .openExtended {
    display: none;
  }
}

@media screen and (max-width: $under-tablet-width) {
  .menu {
    height: calc(100vh - 44px);
    top: 44px;
  }

  .badgeHeight {
    height: 64px;
  }
}
