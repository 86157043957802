@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 25;
}

.headerBorder {
  width: 100%;
  height: 136px;
}
.badgeHeight {
  width: 100%;
  height: 40px;
}

.badge {
  @extend %centred-content;
  color: $neutral-white;
  background-color: $primary-color-00;
  padding: 8px;
  width: 100%;

  .text {
    width: 80%;
    text-align: center;
    color: $neutral-white;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }

  .underline {
    text-decoration-line: underline;
  }

  .miniBlock {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $neutral-white;
  height: 80px;
  top: 0;
  left: 0;
  padding: 0 32px;
  gap: 1.5rem;
  box-shadow: 0 1px 0 $secondary-color-03;
}

.menuModalCustomStyles {
  left: 48px;
  top: 54px;
  width: 250px;
}

.leftSide,
.rightSide {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.leftSide {
  justify-content: left;
  width: 100%;
}

.rightSide {
  justify-content: right;
  flex-shrink: 0;

  .dropdowns {
    display: none;
  }
}

.accountIcon {
  width: 40px;
  height: 40px;
}

.menuIcon {
  width: 32px;
  height: 32px;
}

.logo,
.logoLink {
  @extend %centred-content;
  justify-content: flex-start;
  width: 156px;
  flex-shrink: 0;
}

.searchItems {
  position: relative;
  padding-left: 1rem;
  width: 100%;
}

.userLinks,
.rightItems {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.7rem;

  .gearCreateLink {
    color: $primary-color-00;
  }

  .item {
    @extend %centred-content;
    cursor: pointer;
    justify-content: space-between;
    color: $secondary-color-01;
    white-space: nowrap;
    gap: 10px;
  }
  .buttonItem {
    @extend %centred-content;
    cursor: pointer;
    justify-content: center;
    color: $secondary-color-01;
    white-space: nowrap;
    gap: 10px;
    height: 48px;
    width: 145px;
    border: 1px solid $secondary-color-00;
    border-radius: 8px;

    &:hover {
      background-color: #FAFAFA;
    }

    span {
      font-family: "Goldplay-SemiBold";
    }
  }
}

.modalContainer {
  @extend %centred-content;
  //@extend %styledScroll;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  .cower {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
    opacity: 0;
  }

  .notificationsOverlay {
    position: fixed;
    top: 64px;
    right: 24px;
    width: 432px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 24px #48555E26;
    overflow: hidden;
    max-height: 90vh;
    z-index: 100;

    .borderBottom {
      border-bottom: 1px solid $secondary-color-03;
    }

    .scrollContainer {
      @extend %hidden-scroll;
      overflow-y: auto;
      position: relative;
      pointer-events: inherit;
    }

    .header {
      height: 56px;
      width: 100%;
      padding: 16px;
      flex-direction: row;
      justify-content: space-between;

      .title {
        color: $secondary-color-00;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .link {
      color: $primary-color-00;
      font-weight: 500;
      font-size: 14px;
    }

    .footer {
      height: 40px;
      width: 100%;
      padding: 8px 16px;
      border-top: 1px solid $secondary-color-03;
    }
  }
}

.signButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  white-space: nowrap;

  button {
    width: 114px;
  }
}

.searchMinimized {
  display: none;
}

.burgerIcon {
  display: none;
  flex-shrink: 0;
}

@media screen and (max-width: $desktop-width) {
  .header {
    .userLinks {
      width: 0;
      overflow: hidden;
    }
  }

  .rightSide {
    gap: 0.25rem;
    min-width: 72px;
  }
}

@media screen and (max-width: $tablets-width) {
  .searchItems {
    display: none;
  }

  .searchMinimized {
    position: relative;
    display: block;
  }

  .burgerIcon {
    display: block;
    width: 32px;
    height: 32px;
  }

  .signButtons {
    display: none;
  }
}

@media screen and (max-width: $under-tablet-width) {
  .container {
    width: 100vw;
  }
  .header {
    padding: 0 1rem;
    height: 44px;
  }

  .badgeHeight {
    height: 64px;
  }

  .headerBorder {
    height: 45px;
  }

  .rightSide {
    gap: 0.5rem;
  }

  .leftSide,
  .rightSide {
    .menuIcon,
    .accountIcon,
    .burgerIcon {
      width: 24px;
      height: 24px;
    }

    .logoLink {
      position: relative;
      justify-content: center;
      width: 100%;
    }

    .logo {
      width: 90px;
      position: absolute;
      left: calc(50vw - 45px - 100px);
    }

    .searchMinimized {
      width: 32px;
    }
  }

  .accountIcon {
    width: 24px;
    height: 24px;
  }

  .modalContainer {
    .notificationsOverlay {
      width: 92vw;
      right: 4vw;
    }
  }
}
