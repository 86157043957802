@import '../../theme/variables';

.item {
  min-width: 256px;
  height: 36px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;

  &:hover {
    background-color: $neutral-gray;
  }

  p {
    color: $secondary-color-02;
    font-size: 0.88rem;
  }

  .dark {
    color: $secondary-color-00;
  }
}

.notification {
  color: $neutral-white !important;
}
