@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  width: 100%;
  padding: 12px 16px;
  flex-direction: row;
  display: flex;
}

.background-true {
  background-color: $neutral-white;
}

.background-false {
  background-color: $primary-color-05;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.textContainer {
  margin-left: 16px;
  flex: 1;
}

.notificationContent {
  font-size: 14px;
  line-height: 20px;
  color: $secondary-color-01;
}

.readRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
}

.time {
  font-size: 14px;
  line-height: 20px;
  color: $secondary-color-02;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: $secondary-color-03;
  margin: 0 8px;
}

.read {
  font-size: 14px;
  line-height: 20px;
  color: $primary-color-00;
}
