@import '../../theme/variables.scss';

.wrapper {
  display: flex;
  height: 48px;
  width: 100%;
  position: relative;
  border: 1px solid $secondary-color-03;
  border-radius: 8px;

  &.commonSearchWrapper {
    max-width: 532px;
  }

  .dropdown {
    width: 90px;
    height: 3rem;
    flex-shrink: 0;
    display: none;

    &.focusedDropdown {
      display: block;
    }
  }

  .dropdownElement {
    div {
      border: unset;
    }
  }

  .optionsIndent {
    width: 1rem;
  }

  .searchInput {
    width: 100%;

    .input {
      padding-right: 24px;
      padding-left: 40px;
      height: 46px;
      border: unset;
      width: 100%;
    }

    .mobileCutInput {
      padding-right: 24px;
      height: 44px;
      border: unset;
      width: 0;
    }

    .inputMobile {
      padding-right: 24px;
      height: 46px;
      border: unset;
      width: 100%;
    }
  }
}

@media screen and (max-width: $tablets-width) {
  .commonSearch {
    margin: 0.5rem 1rem;
  }
  .wrapper {
    height: 44px;
  }

  .headerSearch {
    .focusedWrapper {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 40;
      width: 100vw;
      height: 44px;
      padding: 0 16px;
      background-color: $neutral-white;
      border-radius: unset;
      max-width: unset;
      border-top: unset;

      input {
        height: 44px !important;
      }
    }

    .wrapper {
      border: none;
    }
  }
}

@media screen and (max-width: $tablets-width) {
  .searchInput {
    .mobileCutInput {
      padding: 16px;
    }
  }
}
