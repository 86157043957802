@import '../../theme/variables.scss';

.contentWrapper {
  min-height: calc(100vh - 146px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentContainer {
    width: 100%;
    max-width: 1920px;
  }
}

.purchaseHeader {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid $secondary-color-03;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  margin-bottom: 8px;

  .purchaseHeaderTitle {
    font-size: 16px;
    font-weight: 500;
    color: $secondary-color-00;
  }
}

@media screen and (max-width: $tablets-width) {
  .contentWrapper {
    min-height: calc(100vh - 172px);
  }
}

@media screen and (max-width: 743px) {
  .purchaseHeader {
    justify-content: center;
  }
  .contentWrapper {
    min-height: calc(100vh - 247px);
  }

  .desktopOnly {
    display: none;
    color: $secondary-color-00;
  }
}
